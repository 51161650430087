import * as React from 'react';
import classnames from 'clsx';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonBackground from '../../MenuButtonBackground';
import MenuButtonRoot from '../../MenuButtonRoot';
import type { MenuButtonProps } from '../../../MenuButton.types';

type OverlineMenuButtonNSkinProps = MenuButtonProps & {
  skinsStyle: any;
  skin:
    | 'OverlineMenuButtonNSkin'
    | 'OverlineMenuButtonHorizontalMenuAdaptationSkin';
};

const OverlineMenuButtonNSkin: React.FC<
  OverlineMenuButtonNSkinProps
> = props => {
  const {
    id,
    'aria-haspopup': ariaHasPopup,
    'aria-describedby': ariaDescribedBy,
    'aria-current': ariaCurrent,
    'aria-expanded': ariaExpanded,
    isMoreButton,
    dir,
    textAlign,
    positionInList,
    link,
    skinsStyle,
    skin,
    role,
  } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classnames(props.className, skinsStyle[skin])}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup,
            ariaDescribedBy,
            isMoreButton,
            ariaCurrent,
            ariaExpanded,
            role,
          }}
          link={link}
          className={skinsStyle.linkElement}
        >
          <div className={skinsStyle.gapper}>
            <div className={skinsStyle.wrapper}>
              <MenuButtonBackground
                wrapperProps={{ dir, textAlign, id }}
                classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
              >
                {label}
              </MenuButtonBackground>
            </div>
          </div>
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default OverlineMenuButtonNSkin;
